import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { Container, Row, Col } from 'react-grid-system';
import Head from 'components/head';
import Logo from 'components/logo';
import Title from 'components/title';
import Gallery from 'components/gallery';
import { graphql } from 'gatsby';
// import Plyr from 'react-plyr';
// import styled from 'styled-components';
// require('../../assets/css/work.css');

const centerItems = {
  textAlign: 'center',
};

// const LoadTop = styled.div`
//   margin-top: -4rem;
// `;

const Work = ({ data }) => (
  <Layout>
    <Head pageTitle={data.workJson.title} />
    {/* <LoadTop>
      <Plyr
        videoId="bTqVqk7FSmY"
        title="View From A Blue Moon"
        controls={['play-large', 'progress', 'restart']}
        debug
      />
    </LoadTop>
    <div style={{ height: '5vh' }} /> */}
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <div style={centerItems}>
            <Logo />
            <Title as="h1" size="large">
              {data.workJson.title}
            </Title>
          </div>
        </Col>
      </Row>
    </Container>
    <div style={{ height: '5vh' }} />
    {/* <Container>
      <Row>
        <Col col={12} sm={12}>
          <Title as="h2" size="large">
            {data.workJson.content.childMarkdownRemark.rawMarkdownBody}
          </Title>
        </Col>
      </Row>
    </Container> */}
    <Container fluid>
      <Row>
        <Col col={12} sm={12}>
          <div className="gallery__work">
            <Gallery items={data.workJson.gallery} />
          </div>
        </Col>
      </Row>
    </Container>
    <div style={{ height: '5vh' }} />
  </Layout>
);

Work.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Work;

export const query = graphql`
  query WorkQuery {
    workJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        path
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
