import React from 'react';
import logo from './logo_flyjetcenter.svg';
import { Wrapper } from './logo.css';

const Logo = () => (
  <Wrapper>
    <img src={logo} alt="Fly Jet Center" />
  </Wrapper>
);

export default Logo;
